import { Personal, Com } from '@/api/index'
//个人中心
export async function personalCenter(data) {
    let res = Personal.personalCenter(data)
    return res
}

// 修改资料
export async function changeData(data) {
    let res = Personal.changeData(data)
    return res;
}

// export async function upFileCom(data) {
//     let res = Com.upFileCom(data)
//     return res;
// }

export async function upFileCom(data) {
    let res = Com.upFileCom(data)
    return res;
}