<template>
  <my-slot>
    <div class="my_index_c" slot="content" :userPictrue="userPictrue">
      <h3>{{ this.$t("data") }}</h3>
      <div>
        <ul>
          <li>
            <p>{{ this.$t("head") }}:</p>
            <div class="my_index_rt">
              <div class="my_index_rt_1">
                <a>
                  <div class="pictrue_img">
                    <input type="file" id="upfile" ref="clearFile" class="user-pictrue" @change="preview($event)">
                    <img :src="userPictrue" class="avatar" />
                    <div class="click">点击上传</div>
                  </div>
                </a>
              </div>
            </div>
            <div class="clear"></div>
          </li>
          <li>
            <p>{{ this.$t("name") }}:</p>
            <div class="my_index_rt">
              <div class="my_index_rt_2">
                <input v-model="personalData.realName" type="text" />
              </div>
            </div>
            <div class="clear"></div>
          </li>
          <li>
            <p>{{ this.$t("nickname") }}:</p>
            <div class="my_index_rt">
              <div class="my_index_rt_2">
                <input v-model="personalData.userName" type="text" />
              </div>
            </div>
            <div class="clear"></div>
          </li>
          <li>
            <p>{{ this.$t("Gender") }}:</p>
            <div class="my_index_rt">
              <div class="my_index_rt_3">
                <label
                  ><input
                    name="sex"
                    type="radio"
                    value="1"
                    v-model="personalData.gender"
                  />{{ this.$t("male") }}</label
                >
                <label
                  ><input
                    name="sex"
                    type="radio"
                    value="-1"
                    v-model="personalData.gender"
                    checked="checked"
                  />{{ this.$t("female") }}</label
                >
              </div>
            </div>
            <div class="clear"></div>
          </li>

          <li>
            <p>{{this.$t("userMobile")}}:</p>
            <div class="my_index_rt">
              <div class="my_index_rt_2">
                <input v-model="emailAndMobile.userMobile" type="text" disabled/>
              </div>
            </div>
            <div class="clear"></div>
          </li>
          <li>
            <p>{{this.$t("mailbox")}}:</p>
            <div class="my_index_rt">
              <div class="my_index_rt_2">
                <input v-model="emailAndMobile.email" type="text" disabled/>
              </div>
            </div>
            <div class="clear"></div>
          </li>

            <li>
            <p>{{this.$t('unitName')}}:</p>
            <div class="my_index_rt">
              <div class="my_index_rt_2">
                <input v-model="personalData.compName" type="text" />
              </div>
            </div>
            <div class="clear"></div>
          </li>
          <li>
            <p>{{this.$t('laboratory')}}:</p>
            <div class="my_index_rt">
              <div class="my_index_rt_2">
                <input v-model="personalData.labName" type="text" />
              </div>
            </div>
            <div class="clear"></div>
          </li>
          <li>
            <p>{{this.$t('direction')}}:</p>
            <div class="my_index_rt">
              <div class="my_index_rt_2">
                <input v-model="personalData.research" type="text" />
              </div>
            </div>
            <div class="clear"></div>
          </li>
          <li>
            <p>{{this.$t('userAddress')}}:</p>
            <div class="my_index_rt">
              <div class="my_index_rt_2">
                <input v-model="personalData.address" type="text" />
              </div>
            </div>
            <div class="clear"></div>
          </li>

          <li>
            <div class="my_index_rt_4">
              <input
                name=""
                type="button"
                :value="this.$t('save')"
                @click="saveChange"
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </my-slot>
</template>

<script>
import MySlot from "@/components/component/MySlot.vue";
import { changeData, personalCenter,upFileCom } from "./model";
import { showLayer,upFile} from "@/utils/com";
import { IMGURL } from "@/utils/constant";
export default {
  data() {
    return {
      personalData: {
        realName: "",
        gender: "",
        userName: "",
        compName:"",
        labName:"",
        research:"",
        address:""
      },
      emailAndMobile:{
        email: "",
        userMobile:"",
      },
      upFiles: "",
      pictrueUrl: "",
      userPictrue:''
    };
  },
  components: {
    MySlot,
  },
  created() {
     window.localStorage.getItem('userPictrue')? this.userPictrue =window.localStorage.getItem('userPictrue'):this.userPictrue=require("@/assets/img/77.jpeg")
    this.pictrueUrl = IMGURL;
    this.getUserInfo()
  },
  methods: {
    getUserInfo(){
      personalCenter().then((res) => {
      this.emailAndMobile.email = res.email;
      this.personalData.gender = res.gender;
      this.personalData.realName = res.realName;
      this.personalData.userName = res.userName;
      this.emailAndMobile.userMobile = res.userMobile;
      this.personalData.compName = res.compName;
      this.personalData.labName = res.labName;
      this.personalData.research = res.research;
      this.personalData.address = res.address;
    })
    },
    // 用户信息修改
    saveChange() {
      if (
        this.personalData.realName === "" ||
        this.personalData.userName === "" ||
        this.personalData.email === ""
      ) {
        showLayer("toast", "信息不能为空！");
      } else {
        changeData(this.personalData).then((res) => {
          if (res === "ok") {
            showLayer("success", "修改成功！");
          }
        });
      }
    },
      // 用户头像上传
     preview(event){
       this.upFiles = upFile(event)[0]
            upFileCom({
          file:  this.upFiles,
          data: JSON.stringify({ fileClass: "oasis" }),
        }).then(res =>{
          if(res){
             this.userPictrue = IMGURL+ res.filePath;
             showLayer("success", "上传成功！");
              window.localStorage.setItem('userPictrue',this.userPictrue)
          }
        })
    },
 
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 90px;
  height: 90px;
  line-height: 178px;
  text-align: center;
}

.el-icon-plus:before {
  position: absolute;
  top: 0.15rem;
  left: 0.15rem;
}
.my_index_rt_3 {
  margin-top: 0.05rem;
  margin-left: 0.1rem;
}
.pictrue_img {
  position: relative;
  width: 106px;
  height: 106px;
  border: 1px solid #d9d9d9;
}
#upfile {
  display: block;
  opacity: 0;
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0;
   left: 0;
   z-index: 999;
}
.pictrue_img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.click {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 106px;
  height: 20px;
  line-height: 0.1rem;
  text-align: center;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
textarea, input, button {
  border: none;
}
</style>
